<template>
  <div class="content-box">
    <!-- 占位 -->
    <div class="banner-box" v-if="false">
      <img
        class="banner-img"
        src="https://cos.hxclass.cn/test/management/20220928/fbbb05f894f0493a9c21285877ac48c1.jpg"
        alt=""
      />
      <!-- <a-carousel
        effect="fade"
        autoplay
        :autoplaySpeed="8000"
        :key="carouselKey + 'one'"
      >
        <div v-for="(item, index) in bannerList" :key="index + 'two'">
          <img class="banner-img" :src="item.img" alt="" />
        </div>
      </a-carousel> -->
    </div>

    <div class="main-box">
      <!-- 该模块隐掉 -->
      <div class="Tip" v-if="remindList.examRemind && false">
        <img class="image1" src="@/assets/image/study/Vector@2x.png" alt="" />
        <span class="text1">报名提醒：</span>
        <span class="text2"
          >2022年9月16日的<a>孤独症康复教育上岗培训考试</a>开始报名了~</span
        >
        <div class="butn" @click="visible = true">
          点击报名
          <img
            class="change-pic"
            src="@/assets/image/study/20220921-153152.png"
            alt=""
          />
        </div>
      </div>
      <div class="Tip" v-if="false">
        <img class="image1" src="@/assets/image/study/Vector@2x.png" alt="" />
        <span class="text1">通知：</span>
        <span class="text2">下载APP了解考试报名更多</span>
        <div class="butn" @click="visible = true">
          点击了解
          <img
            class="change-pic"
            src="@/assets/image/study/20220921-153152.png"
            alt=""
          />
        </div>
      </div>

      <!-- 在学课程 -->
      <div class="main-bot" id="div0" v-if="$store.state.userInfo.isLogin">
        <!-- 通知条 -->

        <div class="info-title">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">在学课程</p>
              <div
                class="my-homework"
                :class="{ 'my-homeworkTips': isHaveWorkTips }"
                v-if="isHaveWork"
                @click="onHomeWorkPage"
              >
                <img src="@/assets/image/study/20230310-100244.png" alt="" />
                <span>我的作业<a-icon class="icon" type="double-right" /></span>
              </div>
            </div>
            <!-- <div class="category-left">
              <img
                class="category-pic"
                src="@/assets/image/index/pic_category.png"
                alt=""
              />
              <div class="category-text">
                在学课程
                <div class="category-line"></div>
              </div>
              <div class="category-letter">MY STUDY</div>
            </div> -->
            <div
              class="category-right"
              @click="toUrl(1)"
              v-show="courseTotal > 2"
            >
              更多
              <img
                class="change-pic"
                src="@/assets/image/index/pic_more.png"
                alt=""
              />
            </div>
          </div>

          <!-- 分类tabs -->
          <div class="tabs">
            <p
              v-show="tabData.length > 1"
              class="tabItem"
              @click="tabChange(index)"
              :class="{ active: index == tabIndex }"
              v-for="(item, index) in tabData"
              :key="index"
            >
              {{ item.name }}
            </p>
          </div>

          <!-- 列表 -->

          <div class="main-wrap">
            <!-- <h4 class="title-h4">我的课程</h4> -->
            <div class="course-list">
              <template v-for="(item, index) in courseData">
                <div class="course-item" :key="item.courseId" v-if="index < 2">
                  <div class="itemleft">
                    <div class="lefttop">
                      <div class="side-l">
                        <img
                          :src="item.coursePicture"
                          @error="$event.target.src = GLOBAL.defaultImg"
                          alt=""
                          @click="goStudy(item)"
                        />
                      </div>
                      <div class="side-r">
                        <p class="name" @click="goStudy(item)">
                          {{ item.courseName}}
                          <i v-show="item.type != 1"  class="tag" :class="{ 'tag-yellow': item.type == 2 }">
                            {{ item.type == 2 ? "免费": item.type == 3 ? "附赠": ""}}
                          </i>
                        </p>
                        <!-- 线下培训地点 -->
                        <p
                          class="teacherName"
                          v-if="item.province && item.city && item.district"
                        >
                          <img
                            width="11"
                            height="14"
                            src="@/assets/image/index/roup979.png"
                            alt=""
                          />
                          {{ item.provinceTxt }} {{ item.cityTxt }} |
                          {{ item.districtTxt }}
                        </p>
                        <div class="lable" v-else>
                          {{ item.name }}<i>丨</i>视频数量：{{ item.classHour }}个
                          <span class="progress">
                            <img src="@/assets/image/personalCenter/2022092301.png" alt="" />
                            {{item.studyProgress? "已学" + (item.studyProgress * 100).toFixed(0) + "%": "不足1%"}}
                          </span>
                        </div>
                        <!-- 线下培训时间 -->
                        <p
                          class="teacherName"
                          v-if="item.openStartTime && item.openEndTime && item.offline== 1"
                        >
                          <img
                            width="11"
                            height="14"
                            src="@/assets/image/index/roup980.png"
                            alt=""
                          />
                          {{ item.openStartTime }} - {{ item.openEndTime }}
                        </p>
                        <div v-else class="time-inner">
                          <!-- {{item.validDayType}} -->
                          <div class="time-item">
                            <img
                              src="@/assets/image/personalCenter/2022050910.png"
                              alt=""
                            />
                            <span class="timetxt" v-if="item.validDayType == 2"
                              >有效期：长期</span
                            >
                            <span class="timetxt" v-else-if="item.startTime"
                              >有效期：{{ item.startTime }} 至
                              {{ item.endTime }}</span
                            >
                            <span class="timetxt" v-else-if="item.validDayType == 1 && !item.startTime">有效期：{{ item.validDays }}天</span>
                            <span
                              v-if="item.isDelay"
                              @click="toCourseDelay(item)"
                              class="buttxt"
                            >
                            <img src="@/assets/image/study/Group2684.png" alt=""/>
                              课程延期
                            </span>
                          </div>
                          <div v-if="item.isChangeOpenTime" class="time-item">
                            <img
                              src="@/assets/image/personalCenter/2022050911.png"
                              alt=""
                            />
                            <span class="timetxt">
                              班级期数：{{ item.openStartTime }} 至 {{ item.openEndTime }}
                            </span>
                            <span
                              @click="showModal(item)"
                              v-if="item.isChangeClass"
                              class="buttxt"
                              ><img
                                src="@/assets/image/study/Group1596.png"
                                alt=""
                              />更换班级
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="itemright">
                    <!-- 课程进度和按钮 -->
                    <div class="leftbottom">
                      <div class="foot">
                        <a-button
                          class="all-btn-small"
                          v-if="item.isOpenClass == 2"
                          type="primary"
                          disabled
                        >
                          已过期
                        </a-button>
                        <p class="all-btn-small" @click="goStudy(item)">
                          {{
                            item.isOpenClass == 1
                              ? "待开课"
                              : item.status == 1
                              ? "去学习"
                              : item.status == 2
                              ? "已过期"
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <a-spin class="load" v-show="loading" size="large" />
              <!-- 暂无课程 -->
              <a-empty
                v-if="courseData.length == 0 && !loading"
                description=""
                style="padding-top: 50px"
                :image="require('@/assets/image/stateHint/icon_nocourse.png')"
                :image-style="{
                  height: '180px',
                }"
              >
                <span class="nodata"
                  >暂无课程，<a @click="goShop">去逛逛</a></span
                >
              </a-empty>
            </div>
          </div>
        </div>
      </div>

      <!-- 学习资料 -->
      <div class="main-bot" id="div1">
        <div class="info-title">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">学习资料</p>
            </div>
          </div>
          <div class="electron-list">
            <div class="electron-item" v-for="item in electronData" :key="item.id">
              <div class="title">
                <span>
                  <img v-if="item.materialType == 3" src="@/assets/image/personalCenter/20240912-114352.png" alt="">
                  <img v-else src="@/assets/image/personalCenter/20240913-114352.png" alt="">
                  {{item.materialType == 3 ? '公共资料' : '课程资料'}}
                </span>
              </div>
              <div class="side-info" >
                <div class="info">
                  <img class="cover" src="@/assets/image/personalCenter/20240912-114351.png" alt="">
                  <div>
                    <p class="name mallHidden">{{item.name}}</p>
                    <!-- <p class="time" v-if="!item.endTime || item.endTime.substring(0,4) == 9999">长期有效</p>
                    <p class="time" v-else>{{item.startTime.substring(0,10)}} ~ {{item.endTime.substring(0,10)}}</p> -->
                  </div>
                </div>
                <div class="info-name" v-if="item.courseName" :title="item.courseName">
                  {{item.materialType == 3 ? "资料包名称" : "课程名称"}}：{{item.courseName}}
                </div>
                <div class="btn">
                  <p class="download" v-if="item.downloadFlag == 1" @click="onDownLoad(item.url,item.name)">立即下载</p>
                  <p class="all-btn-small" @click="lookmaterial(item)">点击查看</p>
                </div>
              </div>
            </div>
            <template v-if="electronData.length == 0 && !loadingElectron">
              <no-message :msg="'暂无数据'" />
            </template>
          </div>
          <a-spin class="load" v-show="loadingElectron" size="large" />
          <div class="load-more" v-if="electronData.length < electronTotal">
            <a-button :loading="loadingElectron" @click="onLoadMoreElectronList">{{loadingElectron?'正在加载':'查看更多'}}<a-icon v-show="!loadingElectron" type="down"  /></a-button>
          </div>
        </div>
      </div>

      <!-- 考试报名 -->
      <div class="main-bot" id="div2">
        <div class="info-title">
          <a-spin class="spin" v-show="loadingSignup" />

          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">考试报名</p>
            </div>
          </div>

          <!-- 分类tabs -->
          <div class="tabs" v-if="$store.state.userInfo.isLogin">
            <p
              class="tabItem"
              @click="onTabSignup(0)"
              :class="{ active: 0 == examTabIndex }"
            >
              考试报名
            </p>
            <p
              class="tabItem"
              @click="onTabSignup(1)"
              :class="{ active: 1 == examTabIndex }"
            >
              我的考试
            </p>
          </div>

          <!-- 考试报名列表 -->
          <div class="exam-signup-list" v-show="examTabIndex == 0">
            <template v-if="examSignupData.length">
              <div
                class="exam-signup-item"
                v-for="item in examSignupData"
                :key="item.configId"
              >
                <p class="name">{{ item.examName }}</p>
                <p class="time">
                  <!-- 报名时间：{{ manageSignup(item.signupDate) }} -->
                  报名时间：{{ item.signupDate }}
                </p>
                <p class="time">考试时间：{{ item.examDate }}</p>
                <div class="btn">
                  <p
                    class="all-btn-small"
                    @click="toSignUpDetail(item.configId, 1)"
                  >
                    考试报名
                  </p>
                </div>

                <img
                  class="left-bottom-icon"
                  src="@/assets/image/study/signup1.png"
                  alt=""
                />
              </div>
            </template>
            <template v-else-if="examSignupData.length == 0 && !loadingSignup">
              <no-message :msg="'暂无考试安排'" />
            </template>
            <div class="load-more" v-if="examSignupData.length < examSignupTotal">
              <a-button :loading="loadingSignup" @click="onLoadMoreClick">{{loadingSignup?'正在加载':'查看更多'}}<a-icon v-show="!loadingSignup" type="down"  /></a-button>
            </div>
          </div>

          <!-- 我的考试列表 -->
          <!-- auditStatus  审核状态 1未审核 2已审核 3已驳回 4.待完善 -->
          <!-- examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过 -->
          <!-- isExamEnd 考试是否结束  1.是 0.否 -->
          <!-- isHideTimes 是否隐藏场次按钮 1.是  0.否 -->
          <!-- orgId 是否机构报名 有值：机构报名   空值：个人报名 -->
          <!-- mailingMode 机构确认状态  0.个人确认 1.统一邮寄 2.机构未确认 -->
          <div class="exam-signup-list" v-show="examTabIndex == 1">
            <template v-if="myExamData.length">
              <div
                class="exam-signup-item"
                :class="
                  item.auditStatus == 2 && item.examStatus == 3
                    ? 'exam-signup-item1'
                    : item.examStatus == 2
                    ? 'exam-signup-item2'
                    : ''
                "
                v-for="item in myExamData"
                :key="item.configId"
              >
                <p class="name">{{ item.courseName }}</p>
                <div
                  class="replace"
                  v-if="item.auditStatus == 1 || item.auditStatus == 4"
                >
                  <p class="time">
                    报名场次：{{ item.examStartDate.substring(0, 16) }}-{{
                      item.examEndDate.substring(11, 16)
                    }}
                  </p>
                  <p
                    class="time modify"
                    v-if="!item.orgId && item.isShowTimes"
                    @click="toChangeExam(item.signupId, item.signupUserId)"
                  >
                    <img
                      src="@/assets/image/study/20230516-111731.png"
                      alt=""
                    />
                    更换场次
                  </p>
                </div>
                <!-- 未考试/已驳回/未通过 -->
                <div
                  v-if="
                    item.auditStatus == 3 ||
                    item.auditStatus == 0 ||
                    item.examStatus == 4
                  "
                >
                  <p class="time">
                    报名时间：{{
                      item.nearSignupMsgVO
                        ? item.nearSignupMsgVO.signupStartDate.substring(0, 10) +
                          " 至 " +
                          item.nearSignupMsgVO.signupEndDate.substring(0, 10)
                        : "暂无安排"
                    }}
                  </p>
                  <p class="time">
                    考试时间：{{
                      item.nearSignupMsgVO
                        ? item.nearSignupMsgVO.examStartDate.substring(0, 10)
                        : "暂无安排"
                    }}
                  </p>
                </div>
                <!-- 待考试 -->
                <div
                  v-if="
                    item.auditStatus == 2 &&
                    (item.examStatus == 1 || item.examStatus == 2)
                  "
                >
                  <p class="time">
                    考试日期：{{
                      item.examStartDate
                        ? item.examStartDate +
                          "-" +
                          item.examEndDate.substring(11, 19)
                        : "暂无安排"
                    }}
                  </p>
                  <p class="time">
                    考试网站：<a target="_blank" href="https://exam.hxclass.cn/"
                      >https://exam.hxclass.cn</a
                    >
                  </p>
                </div>
                <div
                  class="time score"
                  v-if="item.auditStatus == 2 && item.examStatus == 3"
                >
                  <span v-if="item.totalScore"
                    >总成绩：<i>{{ item.totalScore }}</i
                    >分</span
                  >
                  <span v-if="item.examScore"
                    >考试成绩：<i>{{ item.examScore }}</i
                    >分</span
                  >
                  <span v-if="item.normalScore"
                    >平时成绩：<i>{{ item.normalScore }}</i
                    >分</span
                  >
                  <span v-if="item.subjectivityScore"
                    >主观题成绩：<i>{{ item.subjectivityScore }}</i
                    >分</span
                  >
                </div>
                <div class="btn">
                  <p
                    class="all-btn-detail"
                    v-if="item.auditStatus != 0"
                    @click="toSignUpDetail(item.configId, 2)"
                  >
                    查看详情
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="item.auditStatus == 1 || item.auditStatus == 4"
                    @click="toMessage(item, 1)"
                  >
                    {{ item.auditStatus == 4 ? "完善信息" : "修改信息" }}
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="
                      item.auditStatus == 3 ||
                      item.auditStatus == 0 ||
                      item.examStatus == 4
                    "
                    @click="toSignUpDetail(item.configId, 1)"
                  >
                    考试报名
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="
                      item.auditStatus == 2 &&
                      (item.examStatus == 1 || item.examStatus == 2)
                    "
                    @click="toMessage(item, 2)"
                  >
                    查看信息
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="
                      item.examStatus == 3 && item.isConfirm == 0 && !item.orgId && item.userConfirmFlag
                    "
                    @click="toConfirm(item)"
                  >
                    确认信息
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="
                      item.examStatus == 3 &&
                      item.isConfirm == 0 &&
                      item.orgId &&
                      item.mailingMode == 0
                    "
                    @click="toConfirmOk(item)"
                  >
                    证件照确认
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="
                      (item.examStatus == 3 && item.isConfirm == 1) ||
                      (item.orgId && item.mailingMode == 1)
                    "
                    @click="toConfirmDetail(item)"
                  >
                    证书信息
                  </p>
                  <p
                    class="all-btn-small"
                    v-if="
                      item.examStatus == 3 &&
                      item.orgId &&
                      item.mailingMode == 2
                    "
                  >
                    待机构确认
                  </p>
                </div>

                <img
                  class="left-bottom-icon"
                  src="@/assets/image/study/signup1.png"
                  alt=""
                />
                <!-- 考试状态 -->
                <template>
                  <img
                    class="exam-status-icon"
                    v-if="item.auditStatus == 2 && item.examStatus == 3"
                    src="@/assets/image/study/20230516-111711.png"
                    alt=""
                  />
                  <!-- 已通过 -->
                  <img
                    class="exam-status-icon"
                    v-else-if="
                      item.auditStatus == 0 ||
                      item.auditStatus == 3 ||
                      item.examStatus == 4
                    "
                    src="@/assets/image/study/20230516-111720.png"
                    alt=""
                  />
                  <!-- 未考试 -->
                  <img
                    class="exam-status-icon"
                    v-else-if="item.auditStatus == 1 || item.auditStatus == 4"
                    src="@/assets/image/study/20230516-111705.png"
                    alt=""
                  />
                  <!-- 待审核 -->
                  <img
                    class="exam-status-icon"
                    v-else-if="item.auditStatus == 2 && item.examStatus == 1"
                    src="@/assets/image/study/20230516-111730.png"
                    alt=""
                  />
                  <!-- 待考试 -->
                  <img
                    class="exam-status-icon"
                    v-else-if="item.examStatus == 2"
                    src="@/assets/image/study/20230516-111716.png"
                    alt=""
                  />
                  <!-- 进行中 -->
                </template>
              </div>
            </template>
            <template v-else>
              <no-message :msg="'暂无报名信息'" />
            </template>
          </div>
        </div>
      </div>

      <!-- 模拟考试列表 -->
      <div class="main-bot" id="div3" v-if="$store.state.userInfo.isLogin">
        <div class="info-title">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">模拟考试</p>
            </div>
            <!-- <div class="category-left">
              <img
                class="category-pic"
                src="@/assets/image/index/pic_category.png"
                alt=""
              />
              <div class="category-text">
                模拟考试
                <div class="category-line"></div>
              </div>
              <div class="category-letter">MOCK TEST</div>
            </div> -->
            <div
              class="category-right"
              v-if="MockList.length >= 4"
              @click="toUrl(3)"
            >
              更多
              <img
                class="change-pic"
                src="@/assets/image/index/pic_more.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="mockList">
          <div class="listitem" v-for="(item, index) in MockList" :key="index">
            <div class="itemdiv">
              <div class="ititmleft">
                <img :src="item.photo" alt="" />
              </div>
              <div class="ititmright">
                <span class="title">{{ item.mockName }}</span>
                <span class="label"
                  >时长：{{ item.time }}分钟 |
                  {{ item.isExam && item.examNum ? item.examNum + "次答题" : "无限制" }}</span
                >
                <a-button
                  @click="onana(item)"
                  class="all-btn-small"
                  type="primary"
                >
                  去考试
                </a-button>
              </div>
            </div>
          </div>
          <!-- 暂无课程 -->
          <no-message :msg="'暂无数据'" v-if="!MockList.length" />
        </div>
      </div>

      <!-- 我的证书 -->
      <div class="main-bot" id="div4" v-if="$store.state.userInfo.isLogin">
        <div class="info-title">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">我的证书</p>
            </div>
            <!-- <div class="category-left">
              <img
                class="category-pic"
                src="@/assets/image/index/pic_category.png"
                alt=""
              />
              <div class="category-text">
                我的证书
                <div class="category-line"></div>
              </div>
              <div class="category-letter">MY CERTIFICATES</div>
            </div> -->
            <!-- <div class="category-right" @click="toUrl(2)">
              更多
              <img
                class="change-pic"
                src="@/assets/image/index/pic_more.png"
                alt=""
              />
            </div> -->
          </div>
        </div>
        <div class="info-tab">
          <div class="tab-left" v-if="tabList.length > 0"></div>
          <div class="swiper" ref="infoWidth">
            <swiper
              ref="infoSwipert"
              :options="infoOption"
              v-if="tabList.length > 0"
            >
              <swiper-slide v-for="(item, index) in tabList" :key="index">
                <div class="industryitem">
                  <div class="tab-list">
                    <div
                      class="tab-title"
                      :class="{ 'tab-active': item.isClick }"
                    >
                      {{ item.newsTypeName }}
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div
                v-show="showInfoWidth"
                class="swiper_btn swiper-button-white info-next"
                slot="button-next"
                @mouseover="changeMaskInfo(1)"
                @mouseout="changeMaskInfo(2)"
              >
                <img
                  v-show="swiperRightInfo"
                  src="@/assets/image/index/icon_next.png"
                  alt=""
                />
                <img
                  v-show="!swiperRightInfo"
                  src="@/assets/image/index/icon_next1.png"
                  alt=""
                />
              </div>
            </swiper>
          </div>
        </div>

        <div class="info-content">
          <div class="swiper" ref="tabWidth">
            <template>
              <swiper
                ref="tabSwiper"
                :options="recommendOption"
                v-if="infoList.length"
              >
                <swiper-slide
                  v-for="(item, index) in infoList"
                  :key="index + 'seven'"
                >
                  <div class="slides">
                    <div class="imginfo">
                      <viewer>
                        <img
                          :class="typeID == 2 ? 'img2' : 'img1'"
                          :src="item.picture"
                          alt=""
                        />
                      </viewer>

                      <div class="txtinfo" :title="item.name">
                        <a-tooltip placement="bottom">
                          <template slot="title"> {{ item.name }} </template>
                          {{ item.name }}
                        </a-tooltip>
                      </div>
                      <div class="txtnum">
                        <span v-if="item.code">证书编号：{{ item.code }}</span>
                        <span v-if="item.code"
                          >所获时间：{{ item.getcertifTime }}</span
                        >
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>

              <div
                class="swiper-button-prev"
                @mouseover="changeMaskr(1)"
                @mouseout="changeMaskr(2)"
                v-show="infoList.length > 3"
              >
                <img
                  class="proright"
                  v-show="swiperLeftr"
                  src="@/assets/image/knowledgeMall/bannerLeft2.png"
                  alt=""
                />
                <img
                  class="proright"
                  v-show="!swiperLeftr"
                  src="@/assets/image/knowledgeMall/bannerLeft.png"
                  alt=""
                />
              </div>
              <div
                class="swiper-button-next"
                @mouseover="changeMaskr(3)"
                @mouseout="changeMaskr(4)"
                v-show="infoList.length > 3"
              >
                <img
                  class="proleft"
                  v-show="swiperRightr"
                  src="@/assets/image/knowledgeMall/bannerRight2.png"
                  alt=""
                />
                <img
                  class="proleft"
                  v-show="!swiperRightr"
                  src="@/assets/image/knowledgeMall/bannerRight.png"
                  alt=""
                />
              </div>
            </template>
          </div>

          <no-message :msg="'暂无证书'" v-if="!infoList.length" />
          <a-spin class="spin" v-show="loadingInfo" />
        </div>
      </div>

      <!-- 学习统计 -->
      <div class="main-bot" id="div5" v-if="$store.state.userInfo.isLogin">
        <div class="info-title">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">学习统计</p>
            </div>
          </div>
        </div>
        <!-- 图 -->
        <div class="bottom-content">
          <div class="top">
            <div class="header-top">
              <span>学习时长</span>
              <a-dropdown class="dropdowns">
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  <span class="dspan">{{ timetxts }}</span>

                  <a-icon style="color: #333" type="down" />
                </a>
                <a-menu slot="overlay" @click="getEstablish">
                  <a-menu-item :key="1"> 一周 </a-menu-item>
                  <a-menu-item :key="2"> 半月 </a-menu-item>
                  <a-menu-item :key="3"> 一月 </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
            <div class="mleft">
              <span>学习情况</span>
            </div>
            <div class="mright">
              <span class="all-title">考试情况</span>
            </div>
          </div>
          <div class="bottoms">
            <div>
              <div id="container"></div>
            </div>

            <div class="line"></div>
            <div v-if="estlist.length == 0 && !loadingbot">
              <no-message :msg="'暂无数据'" />
            </div>
            <div v-show="estlist.length">
              <div id="containers"></div>
            </div>

            <div class="line"></div>

            <!-- 考试情况 -->
            <div class="duration-box">
              <!-- <a-dropdown
                class="dropdowns"
                style="width: 120px; margin: 56px 0px 16px 0px"
              >
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  <span class="dspan" style="color: #333">考试情况</span>
                  <a-icon style="color: #333" type="down" />
                </a>
                <a-menu slot="overlay" @click="onClick">
                  <a-menu-item key="1"> 考试情况 </a-menu-item>
                  <a-menu-item key="2"> 模拟考试 </a-menu-item>
                </a-menu>
              </a-dropdown> -->
              <!-- 表格 -->
              <div v-if="examCaseList.length == 0 && !loadingbot">
                <no-message :msg="'暂无数据'" />
              </div>
              <div class="table" v-else>
                <div class="table_left">
                  <div class="item_txt item_main">考试</div>
                  <div
                    class="item_txt"
                    v-for="(item, index) in examCaseList"
                    :key="index"
                    :title="item.examName"
                  >
                    {{ item.examName }}
                  </div>
                  <!-- <div class="item_txt">进阶考试</div>
            <div class="item_txt">itsn考试</div> -->
                </div>
                <div class="table_right">
                  <div
                    :id="index + 1"
                    class="right_item"
                    :class="
                      gradeList.length == 1
                        ? 'right_item1'
                        : gradeList.length == 2
                        ? 'right_item2'
                        : ''
                    "
                    v-for="(item, index) in gradeList"
                    :key="index"
                  >
                    <div class="item_txt item_main">第{{ index + 1 }}次</div>
                    <div
                      class="item_txt"
                      v-for="(itemI, indexI) in item"
                      :key="indexI"
                    >
                      {{ itemI }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 知识解析/模拟考试 -->
      <div class="knowledge-list" id="div6">
        <!-- 知识解析 -->
        <div class="client">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">知识解析</p>
            </div>
            
            <div class="category-right" @click="$router.push('/videoList')">
              更多
              <img
                class="change-pic"
                src="@/assets/image/index/pic_more.png"
                alt=""
              />
            </div>
          </div>
          <div class="clientList">
            <div class="clientItem" @click="$router.push({
                path: '/videoDetail',
                query: {
                  id: $AES.encode_data(item.id + ''),
                  type: '知识解析',
                  from: '学习中心'
                }
              })" v-for="(item,index) in clientList" :key="index">
              <img :src="item.banner" alt="">
              <div class="content">
                <p class="title">{{ item.articleTitle }}</p>
                <div class="label" :title="item.label ">
                  <p v-for="(i,j) in item.label.split('，')" :key="j">#{{ i }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 模拟考试 -->
        <div class="Category" v-if="!$store.state.userInfo.isLogin">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">模拟考试</p>
            </div>
          </div>
          <div class="CategoryList" @scroll="handleInfiniteOnLoad($event)">
            <div class="categoryItem" @click="$router.push(
                  '/knowledgeMall/examDetail?productId=' +
                    $AES.encode_data(item.productId + '') +
                    '&name=学习中心'
                )" v-for="(item,index) in CategoryList" :key="index">
              <img class="mockExamIcon" src="@/assets/image/study/mockExamIcon.png" alt="">
              <div class="content">
                <p class="name">{{ item.name }}</p>
                <p class="describe">{{ item.answerNumber }}</p>
              </div>
              <div class="GodetailBtn">
                <p>进入</p>
                <img src="@/assets/image/index/mock_more.png" alt="">
              </div>
            </div>
          </div>
          <div class="opaBox"></div>
        </div>
      </div>
    </div>

    <!-- 侧边栏 -->
    <div class="side-box">
      <div class="weside-left" v-if="showWeSideLeft">
        <img src="@/assets/image/nav/banner_wechat.png" alt="" />
        <div class="triangle"></div>
      </div>

      <div class="side-left" v-if="showSideLeft">
        <div class="top">联系方式</div>
        <div class="bottom">
          <div>公司地址:北京市东城区东直门外香河园北里4号</div>
          <div>邮政编码:100021</div>
          <div>电子邮件:class@hxph.com.cn</div>
          <div>联系电话:400-800-9002</div>
        </div>
        <div class="triangle"></div>
      </div>

      <div class="side-right" v-show="showSide">
        <div
          class="first-side"
          @mouseover="showWeSideLeft = true"
          @mouseout="showWeSideLeft = false"
        >
          <img
            class="imgw"
            v-if="!showWeSideLeft"
            src="@/assets/image/index/icon_chat.png"
            alt=""
          />
          <img
            class="imgw"
            v-else
            src="@/assets/image/index/icon_chat2.png"
            alt=""
          />
        </div>

        <div
          class="second-side"
          @mouseover="showSideLeft = true"
          @mouseout="showSideLeft = false"
        >
          <img
            class="imgc"
            v-if="!showSideLeft"
            src="@/assets/image/index/icon_contact.png"
            alt=""
          />
          <img
            class="imgc"
            v-else
            src="@/assets/image/index/icon_contact2.png"
            alt=""
          />
        </div>

        <a-back-top
          class="third-side"
          @mouseover="showTopSideLeft = true"
          @mouseout="showTopSideLeft = false"
        >
          <!-- <div class="third-side"> -->
          <!-- <a-back-top> -->
          <img
            class="imgt"
            v-if="!showTopSideLeft"
            src="@/assets/image/index/icon_backtop.png"
            alt=""
          />
          <img
            class="imgt"
            v-else
            src="@/assets/image/index/icon_backtop2.png"
            alt=""
          />
          <!-- </a-back-top> -->
          <!-- </div> -->
        </a-back-top>
      </div>
    </div>

    <div class="side-point" v-if="$store.state.userInfo.isLogin">
      <div class="point-ul">
        <div
          class="point-li"
          @click="navClick(index)"
          :class="{ 'point-li-active': pointNum == index }"
          v-for="(item, index) in navList"
          :key="index"
        >
          <img :src="pointNum == index ? item.iconActive : item.icon" alt="" />
                    
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    
    <!-- 弹窗 -->
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="visible = false"
    >
      <template #title>
        <a-icon class="icon" type="info-circle" />提示信息
      </template>
      <p class="title">{{ fontText }}</p>
      <div class="imgs">
        <div class="imgItem">
          <img src="@/assets/image/nav/20220619-211727.png" alt="" />
        </div>
      </div>
      <template #footer>
        <p class="all-btn-small" @click="visible = false">我知道了</p>
      </template>
    </a-modal>
    <!-- 延期弹窗 -->
    <a-modal
      :visible="visibles"
      :closable="false"
      :confirm-loading="confirmLoading"
      :width="602"
      @cancel="handleCancel"
    >
      <template slot="title">
        <!-- <a-icon class="icon" type="info-circle" />提示信息 -->
      </template>
      <template slot="footer">
        <div class="bottombtn">
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button type="primary" :loading="loading" @click="onDelay()">
            确认
          </a-button>
        </div>
      </template>
      <div class="centers">
        <div class="h4">申请延期须知</div>
        <p v-if="delayCode == 1005">
          1.本次延期为您首次对本课程进行延期，无需缴纳费用；<br /><br />
          2.申请延期后课程有效期顺延{{
            classdata.days
          }}天，申请成功后即生效，有效期最后一天23:59:59秒失效； <br /><br />
          3.有效期内课程观看次数、章节自测题答题次数均不受限制；
          <br /><br />
          4.超过有效期后如需再次学习，每次延期需缴纳{{
            classdata.nextPrice
          }}元延期费，请您合理安排学习时间； <br /><br />
          5.支付成功后不支持退款。
        </p>
        <p v-else>
          1.本次延期为您第{{ classdata.delayCount }}次对课程进行延期，延期费为{{
            classdata.price
          }}元，缴纳费用后课程有效期顺延{{ classdata.days }}天: <br /><br />
          2.支付成功后即生效，有效期最后一天23:59:59秒失效。
          <br /><br />
          3.有效期内课程观看次数、章节自测题答题次数均不受限制;
          <br /><br />
          4.超过有效期后如需再次学习，则需以本计费规则再次缴纳延期费用，请您合理安排学习时间;
          <br /><br />
          5.支付成功后不支持退款。
        </p>
      </div>
    </a-modal>

    <!-- 答题记录和更换班级弹窗 -->
    <change
      :isAddInvoiceModal="isAddInvoiceModal"
      :courseId="courseId"
      :openTimeId="openTimeId"
      @onCancelAdd="onCancelAdd"
      @onConfirmAdd="onConfirmAdd"
    ></change>
    <!-- 历史记录 -->
    <History
      :ifHistory="ifHistory"
      :courseId="courseId"
      @onino="onino"
    ></History>
    <!-- 卷子 -->
    <analog
      :ifanalog="ifanalog"
      :configId="configId"
      :opendata="opendata"
      @onana="closeana"
    ></analog>

    <!-- 考试更换场次弹窗 -->
    <a-modal
      :width="480"
      :visible="modifyExamShow"
      :footer="false"
      :closable="false"
      @cancel="handleCancel"
      class="exam-modal"
    >
      <div class="exam-modal-box">
        <h4>更换考试场次</h4>
        <div class="box">
          <p class="title">当前报名场次</p>
          <div class="time-inner">
            <p class="time">
              <img src="@/assets/image/study/20230516-111731.png" alt="" />{{
                examObj.timeName
              }}场
            </p>
            <div class="line">
              <span class="time" style="margin-right: 40px"
                >考试时间：{{ examObj.examDateTime }}</span
              ><span class="time"
                >考试时间：{{ manageDuration(examObj.duration) }}</span
              >
            </div>
          </div>
        </div>
        <div class="box">
          <p class="title">以下是可更换的报名场次</p>
          <!-- 可选场次 -->
          <div
            class="time-inner select-inner"
            @click="timeId = item.timeId"
            :class="{ 'select-active': item.timeId == timeId }"
            v-for="item in examList"
            :key="item.timeId"
          >
            <p class="time">
              <img
                v-show="item.timeId != timeId"
                src="@/assets/image/study/20230516-111731.png"
                alt=""
              />
              <img
                v-show="item.timeId == timeId"
                src="@/assets/image/study/20230516-111739.png"
                alt=""
              />
              {{ item.timeName }}场
            </p>
            <div class="line">
              <span class="time" style="margin-right: 40px"
                >考试时间：{{ item.examDateTime }}</span
              ><span class="time"
                >考试时间：{{ manageDuration(item.duration) }}</span
              >
            </div>
          </div>
          <!-- 提交按钮 -->
          <div class="submit-btn">
            <span class="cancel-btn" @click="modifyExamShow = false">取消</span>
            <span class="all-btn-small" @click="changeSession">确认更换</span>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 考试报名弹窗 -->
    <TipsModal
      :isCancelBtnShow="false"
      ConfirmBtnText="我知道了"
      :isPublicTipsShow="isPublicTipsShow"
      :tipsText="tipsText"
      @setEvent="isPublicTipsShow = false"
    />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { downFile } from '@/unit/fun.js'
import change from "@/components/study/change.vue"; // 添加发票抬头
import History from "../../components/study/history.vue"; // 历史记录
import analog from "../../components/study/analog.vue"; // 考试记录

export default {
  // 可用组件的哈希表
  components: { change, History, analog },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      visibles: false,
      delayLoad: false, // 延期防抖
      studyLoad: false, // 去学习防抖
      fontText: "请前往app端进行考试报名，pc端即将上线报名，敬请期待",
      loading: false,
      loadingbot: true, //底部加载动画
      loadingInfo: false,
      showSide: false,
      showWeSideLeft: false,
      showSideLeft: false,
      showTopSideLeft: false,
      carouselKey: new Date().getTime(),
      // 弹窗内容
      visible: false,
      confirmLoading: false,
      courseId: "", //课程id
      openTimeId: "", //班级id
      isAddInvoiceModal: false, //更换班级弹框
      // 历史记录弹窗
      ifHistory: false, //判断
      // 考试记录弹窗
      ifanalog: false, //判断
      opendata: {}, //卷子数据
      // 证书分类tab
      infoShow: "",
      showInfoWidth: false,
      swiperRightInfo: false,
      infoOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        navigation: {
          nextEl: ".info-next",
          disabledClass: "my-button-disabled",
        },
        on: {
          // init: () => {
          //   if (this.infoShow > this.$refs.infoWidth.offsetWidth) {
          //     this.showInfoWidth = true;
          //   }
          // },
          click: () => {
            this.onTab();
          },
        },
      },

      remindList: {},

      //证书列表
      swiperLeftr: false,
      swiperRightr: false,
      recommendOption: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        // spaceBetween: 10,
        centeredSlides: false,
        centeredSlidesBounds: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
      },

      // 课程信息
      // loading: false,
      courseData: [],
      courseTotal: 0,
      electronData:[],
      electronTotal:0,
      electronCurrent:1,
      tabData: [],
      tabIndex: 0,
      examTabIndex: 0,

      typeID: "",
      // vtypeID: "",
      // introduce: "",
      bannerList: [],

      tabList: [
        { newsTypeId: 1, newsTypeName: "中康协认证证书", isClick: true },
        { newsTypeId: 2, newsTypeName: "课程学习证明", isClick: false },
        { newsTypeId: 5, newsTypeName: "CEU学分证明", isClick: false },
        { newsTypeId: 6, newsTypeName: "自研课程证书", isClick: false },
        { newsTypeId: 3, newsTypeName: "个人上传证书", isClick: false },
      ],
      infoList: [],

      // 考试情况
      examCaseList: [],
      // 考试成绩
      gradeList: [],
      // 折线图数据
      establishdata: {},
      estlist: [],
      // 课程延期数据
      classdata: {},
      delayCode: null,
      // 显日期的文字
      timetxts: null, //学习情况
      examinationtxt: null, //考试情况

      configId: null, //模拟考试id
      MockList: [], //模拟考试列表

      isHaveWork: false, // 是否有作业
      isHaveWorkTips: false, // 是否有作业提示

      // ***我的考试模块 ***
      modifyExamShow: false, // 考试场次更换弹窗
      examSignupData: [], // 考试报名列表
      examSignupTotal: 0, // 考试报名数量总量
      signupCurrent: 1, // 查询考试报名页码
      myExamData: [], // 我的考试列表
      tipsText: "", // 去报名提示文字
      isPublicTipsShow: false,
      examList: [], //可更换场次列表
      timeId: 0, // 需要更换的场次id
      signupUserId: 0,
      examObj: {},
      loadingSignup: false,
      loadingElectron: false,

      // 锚点定位下标
      pointNum: 0,
      pointLoad: false,
      navList: [
        {
          icon: require("@/assets/image/study/point-icon-active1.png"),
          iconActive: require("@/assets/image/study/point-icon1.png"),
          name: "在学课程",
        },
        {
          icon: require("@/assets/image/study/point-icon-active6.png"),
          iconActive: require("@/assets/image/study/point-icon6.png"),
          name: "学习资料",
        },
        {
          icon: require("@/assets/image/study/point-icon-active2.png"),
          iconActive: require("@/assets/image/study/point-icon2.png"),
          name: "考试报名",
        },
        {
          icon: require("@/assets/image/study/point-icon-active3.png"),
          iconActive: require("@/assets/image/study/point-icon3.png"),
          name: "模拟考试",
        },
        {
          icon: require("@/assets/image/study/point-icon-active4.png"),
          iconActive: require("@/assets/image/study/point-icon4.png"),
          name: "我的证书",
        },
        {
          icon: require("@/assets/image/study/point-icon-active5.png"),
          iconActive: require("@/assets/image/study/point-icon5.png"),
          name: "学习统计",
        },
      ],

      clientList: [], // 未登录状态下知识解析列表
      CategoryList: [], // 未登录模拟考试列表
      category: {
        total: 0,
        pageNum: 1,
        loading: false
      }
    };
  },
  // 事件处理器
  methods: {
    // 资讯列表上拉加载
    handleInfiniteOnLoad(e, list) {
      if(e.target.scrollTop + e.target.clientHeight + 10 >= e.target.scrollHeight){
        if(this.CategoryList.length == this.category.total) {
          return 
        }
        this.category.pageNum++
        this.getCategoryList();
        //触底时进行操作 
        // list.some(item=>{
        //   if(item.columnCode == this.childColumnCode) {
        //     if(item.Infolist.length < item.total) {
        //       this.showList = []
        //       item.pageNum ++;
        //       this.getInfoList(item)
        //     }
        //     return true;
        //   }
        // })
      }
    },

    // 切换考试成绩
    onClick({ key }) {
      console.log(`Click on item ${key}`);
    },
    // 延期查询
    toCourseDelay(e) {
      this.$ajax({
        url: "/hxclass-pc/course/check-delay-course",
        params: {
          courseId: e.courseId,
        },
      }).then((res) => {
        if (res.success) {
          this.classdata = res.data;
          this.delayCode = res.code;
          this.classdata.courseId = e.courseId;
          this.visibles = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 延期
    onDelay() {
      if (this.delayCode == 1005) {
        if (this.delayLoad) return;
        this.delayLoad = true; // 延期防抖
        // 免费延期直接走接口
        this.$ajax({
          url: "/hxclass-pc/course/delay-course",
          method: "POST",
          params: {
            courseDelayId: this.classdata.courseDelayId,
            courseId: this.classdata.courseId,
            days: this.classdata.days,
            price: this.classdata.price,
          },
        }).then((res) => {
          this.delayLoad = false;
          if (res.code == 200 && res.success) {
            this.visibles = false;
            this.$message.success("申请成功");
            this.getCourseList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        // 付费延期走订单
        let list = [];
        list.push({
          productId: this.classdata.courseDelayId, // 商品id
          count: 1, // 数量")
          openTimeId: "", // 班级id
          type: 6, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province: "", // 省
          city: "", // 市
          noGift: 0, // 是否为赠品 0否1是t
          productList: [],
        });
        this.$store.commit("updatelist", list);
        this.$store.commit("updateType", 4);
        this.$store.commit("updateInvoiceId", null);
        this.$store.commit("updateShortInvoiceId", null);
        this.$store.commit("updateAddress", null);
        this.$store.commit("updateCouponList", null);
        this.$router.push("/order/confirmOrder");
      }
    },
    //  取消按钮
    handleCancel(e) {
      this.visibles = false;
      this.modifyExamShow = false;
    },

    // 跳转作业列表
    onHomeWorkPage() {
      this.$router.push("/homework/homeworkList");
    },

    toUrl(e) {
      switch (e) {
        case 1:
          this.$router.push("/study/MyCourse");
          break;
        case 3:
          this.$router.push("/study/mocklist");
          break;

        default:
          break;
      }
    },
    // 按钮
    changeMaskr(e) {
      if (e == 1) {
        this.swiperLeftr = true;
      } else if (e == 2) {
        this.swiperLeftr = false;
      } else if (e == 3) {
        this.swiperRightr = true;
      } else if (e == 4) {
        this.swiperRightr = false;
      }
    },
    onTab() {
      if (this.$refs.infoSwipert.$swiper) {
        let realIndex = this.$refs.infoSwipert.$swiper.clickedIndex;
        if (!isNaN(realIndex)) {
          this.tabList.forEach((item, index) => {
            if (index == realIndex) {
              item.isClick = true;
              this.typeID = item.newsTypeId;
            } else {
              item.isClick = false;
            }
          });
        }
      }
    },

    changeMaskInfo(e) {
      if (e == 1) {
        this.swiperRightInfo = true;
      } else if (e == 2) {
        this.swiperRightInfo = false;
      }
    },
    getBanner() {
      this.$ajax({
        url: "/hxclass-pc/rotation/pc/3",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.bannerList = res.data;
          this.carouselKey = new Date().getTime();
        }
      });
    },

    // 获取证书
    getInfo(typeID) {
      this.loadingInfo = true;
      this.$ajax({
        url: "/hxclass-pc/user-certif/my",
        params: {
          type: typeID,
          pageNum: 1,
          pageSize: 10000,
        },
      }).then((res) => {
        this.loadingInfo = false;
        if (res.code == 200 && res.success) {
          this.infoList = res.data.records;
        }
      });
    },

    // 获取课程数据
    goShop() {
      this.$router.push("/knowledgeMall/shopHome");
    },
    // 获取模拟考试
    getMock() {
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/userCourse/mockExam",
        method: "get",
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          res.data.forEach((item) => {
            let a = item.examTime.substring(0, 2);
            let b = item.examTime.substring(3, 5);
            let time = a * 60 + b * 1;
            item.time = time;
          });
          if (res.data.length >= 4) {
            // 不满足4个就全取，过4个就取4个
            for (let index = 0; index < 4; index++) {
              this.MockList.push(res.data[index]);
            }
          } else {
            this.MockList = res.data;
          }
        }
      });
    },

    tabChange(i) {
      this.tabIndex = i;
      this.courseData = this.tabData[i].courseList;
      this.courseTotal = this.tabData[i].courseList.length;
    },
    // 获取课程列表
    getCourseList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/course/my-classification",
        method: "get",
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success && res.data.length) {
          this.tabData = res.data;
          this.courseData = res.data[this.tabIndex].courseList;
          this.courseTotal = res.data[this.tabIndex].courseList.length;
        }
      });
    },

    onLoadMoreElectronList(){
      this.electronCurrent ++
      this.getElectronList()
    },

    // 查询学习资料
    getElectronList(){
      this.loadingElectron = true;
      this.$ajax({
        url: "/hxclass-pc/user_material/selectUserMaterialList",
        method: "get",
        params: {
          pageNum: this.electronCurrent,
          pageSize: 9,
        },
      }).then((res) => {
        this.loadingElectron = false;
        if (res.code == 200 && res.success) {
          this.electronData.push(...res.data.records)
          this.electronTotal = res.data.total;
        }
      });
    },
    // 获取是否有作业
    getHomeList() {
      this.$ajax({
        url: "/hxclass-pc/homework/student/list",
        params: {
          userId: this.$store.state.userInfo.userId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success && res.data.length) {
          this.isHaveWorkTips = false;
          res.data.forEach((element) => {
            // 作业待提交或未提交 作业按钮增加红点提示
            if (element.status == 1 || element.status == 0) {
              this.isHaveWorkTips = true;
            }
          });
          this.isHaveWork = true;
        } else {
          this.isHaveWork = false;
        }
      });
    },

    // 处理报名日期
    manageSignup(con) {
      if (!con) {
        return;
      }
      if (con == "暂无安排") {
        return con;
      }
      let startDate = con.split("至")[0];
      let endDate = con.split("至")[1];
      return startDate + " 至 " + endDate;
    },

    onLoadMoreClick(){
      this.signupCurrent ++
      this.examSignUpList()
    },

    // 考试报名列表
    examSignUpList() {
      this.loadingSignup = true;
      this.$ajax({
        url: "/hxclass-pc/exam/config/list",
        params: {
          userId: this.$store.state.userInfo.userId,
          current: this.signupCurrent,
          size: 9,
        },
      }).then((res) => {
        this.loadingSignup = false;
        if (res.code == 200 && res.success && res.data.records.length) {
          this.examSignupData.push(...res.data.records)
          this.examSignupTotal = res.data.total
        }
      });
    },
    // 我的考试列表
    myExamList() {
      this.loadingSignup = true;
      this.$ajax({
        url: "/hxclass-pc/exam/msg/near",
      }).then((res) => {
        if (res.code == 200 && res.success && res.data.length) {
          this.myExamData = res.data;
        }
        this.loadingSignup = false;
      });
    },
    // 修改考试场次
    toChangeExam(signupId, signupUserId) {
      this.signupUserId = signupUserId;
      this.getExamList(signupId, signupUserId);
    },
    onTabSignup(index) {
      this.examTabIndex = index;
      if (index == 1 && !this.myExamData.length) {
        // 我的考试列表
        this.myExamList();
      }
    },
    // 查询可更换的考试列表
    getExamList(signupId, signupUserId) {
      this.loadingSignup = true;
      this.$ajax({
        url:
          "/hxclass-pc/exam/time/chanage/list?signupId=" +
          signupId +
          "&signupUserId=" +
          signupUserId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            this.examList = res.data;
            this.getNowExam(signupUserId);
          } else {
            this.$message.error("暂无可更换的场次");
          }
        } else {
          this.$message.error(res.message);
        }
        this.loadingSignup = false;
      });
    },
    // 查询当前考试场次
    getNowExam(signupUserId) {
      this.loadingSignup = true;
      this.$ajax({
        url: "/hxclass-pc/exam/user_signup/time?signupUserId=" + signupUserId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examObj = res.data[0];
          this.modifyExamShow = true;
        } else {
          this.$message.error(res.message);
        }
        this.loadingSignup = false;
      });
    },
    // 更换考试场次
    changeSession() {
      let _this = this;
      if (!_this.timeId) {
        _this.$message.warning("请选择需要更换的场次");
        return;
      }

      _this.$confirm({
        title: "确定更换考试场次吗？",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-pc/exam/signup/edit",
              method: "PUT",
              params: {
                signupUserId: _this.signupUserId,
                timeId: _this.timeId,
              },
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.modifyExamShow = false;
                _this.$message.success("更换成功");
                _this.myExamList();
              } else {
                _this.$message.warning(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
    // 考试时长处理
    manageDuration(con) {
      if (!con) {
        return "";
      }
      let time = "";
      let hour = con.split(":")[0];
      let minute = con.split(":")[1];
      let second = con.split(":")[2];
      if (hour !== "00") {
        if (hour[0] == "0") {
          time += hour.substring(1, hour.length) + "小时";
        } else {
          time += hour + "小时";
        }
      }
      if (minute !== "00") {
        if (minute[0] == "0") {
          time += minute.substring(1, minute.length) + "分钟";
        } else {
          time += minute + "分钟";
        }
        // time += minute+'分钟'
      }
      if (second !== "00") {
        time += second + "秒";
      }
      return time;
    },
    // 消息提醒
    getRemind() {
      this.$ajax({
        url: "/hxclass-pc/exam/user/reminder",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId, //用户id
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.remindList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    goStudy(e) {
      if (e.isOpenClass == 2) {
        return;
      }
      // 没有视频的培训
      if(e.hasSectionFlag==0&&!e.hasVideoFlag&&e.courseClass==2){
        this.$message.warning("您已报名该培训，请根据培训时间参加培训。");
        return
      }
      if (e.status == 2) {
        this.$message.warning("请申请延期后学习");
        return;
      }
      if (!e.hasVideoFlag && !e.hasSectionFlag) {// 没有章节、没有视频，提示文字
        // return this.$message.warning("课程暂未更新，请耐心等待开课")
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
        return
      }
      if (e.isOpenClass == 1) {
        let arr = e.startTime.split("-");
        this.$message.warning(
          "课程学习尚未开始，开始时间为" +
            arr[0] +
            "年" +
            arr[1] +
            "月" +
            arr[2] +
            "日"
        );
      } else {
        if (e.watchByCatalogueOrder == 1) {
          if (this.studyLoad) {
            return;
          }
          this.studyLoad = true;
          this.$ajax({
            url: "/hxclass-pc/course/go-study",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            this.studyLoad = false;
            if (res.code == "200" && res.success) {
              this.$router.push(
                "/curriculum/course?courseId=" +
                  this.$AES.encode_data(String(e.courseId)) +
                  "&isBuy=" +
                  "&isHave=1"
              );
            } else {
              if (res.code == -2) {
                let str =
                  res.message +
                  "若已通过其他平台获得资质，请前往app提交资质进行学习。";
                res.message = str;
              }
              this.$message.warning(res.message);
            }
          });
        } else {
          this.$ajax({
            url: "/hxclass-pc/course/getUserCurrentSection",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            this.studyLoad = false;
            if (res.code == "200" && res.success) {
              this.$router.push(
                "/curriculum/courseRandom?courseId=" +
                  this.$AES.encode_data(String(e.courseId)) +
                  "&isHave=1"
              );
            } else {
              this.$message.warning(res.message);
            }
          });
        }
      }
    },

    // 考试报名/报名详情
    toSignUpDetail(configId, type) {
      if (type == 1) {
        this.loadingSignup = true;
        this.$ajax({
          url: "/hxclass-pc/exam/config/list/verifi",
          params: {
            userId: this.$store.state.userInfo.userId,
            configId: configId,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$router.push(
              "/study/examSignup?configId=" +
                this.$AES.encode_data(String(configId)) +
                "&type=" +
                this.$AES.encode_data(String(type))
            );
          } else {
            this.isPublicTipsShow = true;
            this.tipsText = res.message;
          }
          this.loadingSignup = false;
        });
      } else if (type == 2) {
        this.$router.push(
          "/study/examSignup?configId=" +
            this.$AES.encode_data(String(configId)) +
            "&type=" +
            this.$AES.encode_data(String(type))
        );
      }
    },
  
    // 下载文件
    onDownLoad(url, name) {
      downFile(url,name)
    },

    // 查看电子课件
    lookmaterial(e){
    if(e.downloadFlag == 1){
        window.open(e.url);
      } else{
        window.open('/knowledgeMall/PdfView?link=' + this.$AES.encode_data(String(e.url)));
      }
    },

    // 考试通过确认信息
    toConfirm(e) {
      this.$router.push(
        "/study/signUpConfirmOk?configId=" +
          this.$AES.encode_data(e.configId + "") +
          "&examineeId=" +
          this.$AES.encode_data(e.examineeId + "") +
          "&signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "")
      );
    },

    toConfirmOk(e) {
      this.$router.push(
        "/study/signUpConfirmGroup?configId=" +
          this.$AES.encode_data(e.configId + "") +
          "&examineeId=" +
          this.$AES.encode_data(e.examineeId + "") +
          "&signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "")
      );
    },

    // 证书信息确认详情
    toConfirmDetail(e) {
      this.$router.push(
        "/study/signUpConfirmDetail?signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "")
      );
    },

    // 修改报名信息
    toMessage(e, dis) {
      this.$router.push(
        "/study/registrationMessage?configId=" +
          this.$AES.encode_data(e.configId + "") +
          "&signupId=" +
          this.$AES.encode_data(e.signupId + "") +
          "&timeId=" +
          this.$AES.encode_data(e.timeId + "") +
          "&signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "") +
          "&type=" +
          this.$AES.encode_data(2 + "") +
          "&dis=" +
          this.$AES.encode_data(dis + "")
      );
    },

    // 获取折线图数据,选择学习时间
    getEstablish(e) {
      switch (e.key) {
        case 1:
          this.timetxts = "一周";
          break;
        case 2:
          this.timetxts = "半月";
          break;
        case 3:
          this.timetxts = "一月";
          break;

        default:
          this.timetxts = "全部";
          break;
      }
      this.$ajax({
        url: "/hxclass-pc/course/study-statistics",
        method: "get",
        params: {
          type: e.key,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.establishdata = res.data;
          this.establishBrokenline();
        }
      });
      // 走生成
    },
    // 创建折线图
    establishBrokenline() {
      var dom = document.getElementById("container");
      var myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      var app = {};

      var option;
      option = {
        xAxis: {
          type: "category",
          data: this.establishdata.date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.establishdata.time,
            type: "line",
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option);
      }

      window.addEventListener("resize", myChart.resize);
    },
    // 获取南丁格尔图数据
    getNightingale(e) {
      this.$ajax({
        url: "/hxclass-pc/course/study-situation",
        method: "get",
        params: {
          type: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.establishdata = res.data;
          let arr = [];
          for (let index = 0; index < res.data.length; index++) {
            arr.push({
              value: res.data[index].studyClassHour,
              name:
                res.data[index].courseName +
                " " +
                res.data[index].studyProgress * 100 +
                "%",
            });
          }
          this.estlist = arr;
          if (arr.length) {
            this.Nightingale(arr);
          }
        }
      });
      // 走生成
    },
    // 创建南丁格尔图
    Nightingale(e) {
      var dom = document.getElementById("containers");
      var myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      var app = {};

      var option;

      option = {
        // title: {
        //   text: "同名数量统计",
        //   subtext: "纯属虚构",
        //   left: "center",
        // },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}  ({d}%)",
          // : {c}
        },
        // legend: {
        //   type: "scroll",
        //   orient: "vertical",
        //   right: 10,
        //   top: 20,
        //   bottom: 20,
        //   data: data.legendData,
        // },
        series: [
          {
            name: "学习情况",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            data: e,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option);
      }

      window.addEventListener("resize", myChart.resize);
    },
    // 考试情况
    getExamCase() {
      this.$ajax({
        url: "/hxclass-pc/exam/user/study/exam-score",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId, //用户id
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.examCaseList = res.data;
          // 处理考试成绩数据
          this.switchGrade();
        } else {
          this.$message(res.message);
        }
      });
    },
    // 考试成绩数据处理
    switchGrade() {
      // 最多考试次数
      let maxCount = 0;
      this.examCaseList.map((item, index) => {
        // 第一轮
        if (index == 0) {
          maxCount = item.scoreList.length;
        } else {
          // 向上一位做对比
          if (
            item.scoreList.length >
            this.examCaseList[index - 1].scoreList.length
          ) {
            maxCount = item.scoreList.length;
          }
        }
      });

      let gradeItem = []; //
      for (var i = 0; i < maxCount; i++) {
        for (var j = 0; j < this.examCaseList.length; j++) {
          if (this.examCaseList[j].scoreList[i]) {
            gradeItem.push(this.examCaseList[j].scoreList[i].totalScore);
          } else {
            gradeItem.push("-");
          }
        }
        this.gradeList.push(gradeItem);
        gradeItem = [];
      }
      this.loadingbot = false;
    },
    // 弹窗操作
    showModal(e) {
      // console.log(e);
      this.courseId = e.courseId;
      this.openTimeId = e.openTimeId;
      this.isAddInvoiceModal = true;
    },
    // 更换班级 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false;
    },
    // 更换班级 - 确定
    onConfirmAdd() {
      this.isAddInvoiceModal = false;
    },
    onselino(e) {
      this.ifHistory = !this.ifHistory;
      this.courseId = e;
    },
    // 历史成绩 - 我知道了
    onino() {
      this.ifHistory = !this.ifHistory;
    },
    // 卷子 - 打开
    onana(e) {
      this.opendata = e;
      this.configId = e.configId;
      this.ifanalog = true;
    },
    // 卷子 - 关闭
    closeana() {
      this.ifanalog = false;
    },

    // 随着页面滚动定位右侧导航
    handleScroll(e) {
      if (this.pointLoad) return;
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        let scrollTop =
          e.target.scrollTop ||
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; // 滚动条偏移量
        let num = this.navList.length;
        for (let n = 0; n < num; n++) {
          if (document.querySelector("#div" + n).offsetTop < scrollTop + 100) {
            this.pointNum = n;
          }
        }
      }, 200);
    },

    // 锚点定位
    navClick(index) {
      this.pointNum = index;
      this.pointLoad = true;
      // const returnEle = document.querySelector("#div" + index); //productId是将要跳转区域的id
      // if (!!returnEle) {
      //   returnEle.scrollIntoView(true); // true 是默认的
      // }
      let offsetTop = document.querySelector("#div" + index).offsetTop - 60;
      window.scrollTo(0,offsetTop);
      clearTimeout(this.tiemID1);
      this.tiemID1 = setTimeout(() => {
        this.pointLoad = false;
      }, 500);
    },

    // 未登录状态 - 获取知识解析列表
    // 知识解析 - /hxclass-pc/Article/client/list?
    getclientList() {
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: 'Knowledge',
          queryLevel: 1,
          sortType: 1,
          pageNum: 1,
          pageSize: this.$store.state.userInfo.isLogin?12:8
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.clientList = res.data.records;
        }
      });
    },

    // 未登录模拟考试列表 
    // /hxclass-pc/pc-mall/revisionAppCategoryList?
    getCategoryList() {
      if(this.category.loading){
        return 
      }
      this.category.loading = true
      this.$ajax({
        url: "/hxclass-pc/pc-mall/revisionAppCategoryList",
        params: {
          pageNum: this.category.pageNum,
          pageSize: 10,
          sorting: 1,
          categoryCode: '01'
        },
      }).then((res) => {
        this.category.loading = false;
        if (res.code == 200 && res.success) {
          this.category.total = res.data.total;
          this.CategoryList.push(...res.data.records);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if(this.$store.state.userInfo.isLogin) {
      // 获取课程数据
      this.getCourseList();
      // 查询学习资料
      this.getElectronList();
      // 查询是否有作业
      this.getHomeList();
      // 考试情况,接口出完调用
      this.getExamCase();
      // 模拟考试列表
      this.getMock();
      // 考试报名列表
      this.examSignUpList();
    }else{
      // 未登录模拟考试列表 
      this.getCategoryList();
    }
    // this.getBanner(); // 废弃
    // 消息提醒
    // this.getRemind(); // 废弃 - 模块隐藏
    
    // 获取知识解析列表
    this.getclientList();
    
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$emit("setIndex", true);
    let that = this;
    window.onscroll = function (e) {
      if (window.scrollY < 240) {
        that.showSide = false;
      } else if (window.scrollY > 240) {
        that.showSide = true;
      }
    };
    if(this.$store.state.userInfo.isLogin) {
      // 获取数据
      this.getEstablish({ key: 1 });
      // 获取南丁格尔图数据
      this.getNightingale();

      this.Nightingale();
      // 第一次获取证书列表
      this.getInfo("1");
      document.addEventListener("scroll", this.handleScroll, true);
    }
  },
  // 计算属性监听
  computed: {},
  // 销毁
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  // 自定义的侦听器
  watch: {
    typeID(newval) {
      this.getInfo(newval);
    },
    modifyExamShow() {
      if (!this.modifyExamShow)
        [
          setTimeout(() => {
            this.timeId = 0;
          }, 200),
        ];
    },
  },
};
</script>

<style lang="less" scoped>
.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.content-box {
  overflow: hidden;
  background-color: #f6f6fc;
  padding-top: 60px;

  .banner-box {
    position: relative;
    .banner-img {
      width: 100%;
      max-height: 380px;
      background-repeat: no-repeat;
      object-fit: cover;
    }

    /deep/.ant-carousel .slick-dots-bottom {
      bottom: 41px;
    }
    /deep/.ant-carousel .slick-dots li button {
      width: 40px;
      height: 8px;
    }
    /deep/.ant-carousel .slick-dots li {
      margin: 0 15.5px;
    }
    /deep/.ant-carousel .slick-dots-bottom {
      z-index: 2;
    }
  }

  .main-box {
    margin: 16px auto 60px auto;

    .category-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .category-left {
        position: relative;
        width: 200px;
        .category-pic {
          width: 44px;
          height: 55px;
        }
        .category-text {
          position: absolute;
          top: 9px;
          left: 14px;
          font-size: 28px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #333333;
        }
        .category-letter {
          position: absolute;
          bottom: 1px;
          left: 14px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #085164;
          line-height: 16px;
        }
        .category-line {
          display: inline-block;
          margin-left: 2px;
          width: 10px;
          height: 0px;
          opacity: 0.6;
          border: 1px solid @border-color;
          vertical-align: middle;
        }
      }
      .category-right {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
      .my-homework {
        padding: 3px;
        margin-left: 27px;
        background: rgba(21, 183, 221, 0.1);
        border-radius: 7px;
        line-height: 24px;
        color: @theme;
        position: relative;
        cursor: pointer;
        img {
          margin-right: 6px;
          margin-top: -2px;
          width: 24px;
        }
        .icon {
          font-size: 14px;
        }
      }
      .my-homeworkTips::after {
        content: "";
        position: absolute;
        right: -4.5px;
        top: -4.5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background: #f90000;
      }
    }

    .tabs {
      display: inline-block;
      padding: 0 40px;
      margin-top: 20px;
      margin-bottom: 10px;
      background-color: rgba(21, 183, 221, 0.1);
      .tabItem {
        display: inline-block;
        padding: 0 38px;
        line-height: 38px;
        font-size: 18px;
        color: #666666;
        margin-right: 20px;
        cursor: pointer;
      }
      .tabItem:last-child {
        margin: 0;
      }
      .active {
        color: #ffffff;
        background-color: #15b7dd;
      }
    }

    .main-bot {
      margin-top: 16px;
      // width: 978px;
      background-color: #fff;
      border-radius: 5px;
      position: relative;

      .info-title {
        padding: 30px 30px 24px 30px;
      }

      .info-tab {
        position: relative;
        padding: 0 0 0 20px;
        height: 38px;
        overflow: hidden;

        .tab-left {
          position: absolute;
          left: 0;
          height: 38px;
          width: 20px;
          background: rgba(21, 183, 221, 0.1);
        }
        .tab-list {
          // margin: 0 20px;
          .tab-title {
            cursor: pointer;
            min-width: 104px;
            height: 38px;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 400;
            padding: 0 32px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 38px;
            text-align: center;
          }
          .tab-active {
            color: #fff;
            font-weight: 500;
            background: #15b7dd;
          }
        }
        // 轮播样式
        .industryitem {
          position: relative;
          z-index: 3;
        }
        // /deep/.swiper-container {
        //   overflow: inherit;
        // }
        /deep/.swiper-button-prev:after,
        .swiper-container-rtl .swiper-button-prev:after {
          content: "";
        }
        /deep/.swiper-button-prev:after,
        .swiper-container-rtl .swiper-button-next:after {
          content: "";
        }
        ::v-deep.swiper-button-next {
          position: absolute;
          right: -2px;
          top: 22px;
          z-index: 5;
          height: 38px;
          width: 24px;
          background: #ebf6fb;
          img {
            position: relative;
            width: 7px;
            height: 14px;
          }
        }
        /deep/.swiper-slide {
          width: auto;
        }
        .swiper {
          position: relative;
          width: auto;
          display: inline-block;
          background: rgba(21, 183, 221, 0.1);
          max-width: 100%;
        }
      }

      .info-content {
        position: relative;
        padding: 5px 15px 24px;
        margin-top: 40px;
      }
    }
  }

  .side-box {
    display: flex;

    .weside-left {
      position: fixed;
      right: 42px;
      // left: 50%;
      // margin-left: 766px;
      bottom: 240px;
      margin-right: 20px;
      width: 80px;
      height: 80px;
      background: #ffffff;
      box-shadow: 10px 20px 15px 1px rgba(93, 131, 167, 0.1);
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #eeeeee;
      z-index: 9;

      img {
        width: 80px;
        height: 80px;
        border-radius: 5px 5px 5px 5px;
      }
      .triangle {
        position: relative;
        right: -80px;
        bottom: 44px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid #fff;
        border-bottom: 5px solid transparent;
      }
    }

    .side-left {
      position: fixed;
      right: 42px;
      // left: 50%;
      // margin-left: 590px;
      bottom: 126px;
      margin-right: 20px;
      padding: 0 18px;
      width: 245px;
      height: 190px;
      background: #ffffff;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #eeeeee;
      z-index: 9;

      .top {
        height: 45px;
        border-bottom: 1px solid #eeeeee;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 55px;
        text-align: center;
      }
      .bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 14px 0 11px;
        height: 145px;
        font-size: 10px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
      }
      .triangle {
        position: relative;
        right: -224px;
        bottom: 96px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 10px solid #fff;
        border-bottom: 6px solid transparent;
      }
    }

    .side-right {
      position: fixed;
      right: 0;
      // left: 50%;
      // margin-left: 850px;
      bottom: 185px;
      background: #ffffff;
      width: 42px;
      border-radius: 5px 5px 0px 0px;
      border: 1px solid #c5c1c1;
      z-index: 12;

      .imgw {
        width: 24px;
        height: 20px;
        z-index: 2;
      }
      .imgc {
        width: 23px;
        height: 22px;
        z-index: 2;
      }
      .imgt {
        width: 23px;
        height: 23px;
        z-index: 2;
      }

      .first-side {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        padding: 0 3px;
        border-bottom: 1px solid #c4c4c4;
      }

      .second-side {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 62px;
        padding: 0 3px;
      }

      .third-side {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 59px;
        padding: 0 3px;
        background: #ffffff;
        width: 42px;
        border-radius: 0px 0px 5px 5px;
        border: 1px solid #c5c1c1;
        border-top: none;
      }

      /deep/.ant-back-top {
        position: fixed;
        right: 0;
        // left: 50%;
        // margin-left: 850px;
        bottom: 126px;
      }
    }
  }

  .side-point {
    background: #ffffff;
    border-radius: 20px 0px 0px 20px;
    padding: 26px 2px;
    position: fixed;
    right: 0;
    top: 12%;
    width: 60px;
    z-index: 22;
    .point-ul {
      display: flex;
      flex-wrap: wrap;
      .point-li {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 12px;
        margin-bottom: 26px;
        border: 1px solid #ffffff;
        padding: 3px 0;
        cursor: pointer;
        img {
          width: 12px;
        }
        p {
          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }
      }
      .point-li:last-child {
        margin-bottom: 0;
      }

      .point-li-active {
        background: rgba(141, 141, 141, 0.05);
        border-radius: 6px;
        border: 1px solid #cacaca;
        p {
          color: #333333;
        }
      }
    }
  }
}

// 知识解析
.knowledge-list{
  height: 556px;
  display: flex;
  gap: 18px;
  margin-top: 16px;
  position: relative;
  .client{
    flex: 1;
    padding: 30px;
    border-radius: 5px;
    background-color: #fff;
  }
  .clientList{
    display: flex;
    flex-wrap: wrap;
    gap: 20px 24px;
    padding-top: 24px;
    .clientItem{
      width: 360px;
      padding: 12px;
      display: flex;
      gap: 14px;
      border-top: 4px solid rgba(21, 183, 221, 0.23);
      box-shadow: 0px 4px 10px 0px rgba(93,131,167,0.1);
      cursor: pointer;
      transition: box-shadow .3s;
      img{
        width: 100px;
        height: 62px;
      }
      .content{
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 17px;
        .title{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
        .label{
          width: 100%;
          display: flex;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          p{
            white-space: nowrap;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #15B7DD;
            line-height: 18px;
            background: rgba(21,183,221,0.1);
            margin-right: 6px;
            padding: 0 4px;
          }
        }
      }
    }
    .clientItem:hover{
      box-shadow: 0px 4px 10px 0px rgba(93, 131, 167, 0.20);
    }
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
      /* 对应纵向滚动条的宽度 */
      width: 10px;
      /* 对应横向滚动条的宽度 */
      height: 10px;
  }

  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
      background-color: #F0F0F0;
      border-radius: 32px;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
      background-color: #F9F9F9;
      border-radius: 32px;
  }
  .Category{
    width: 378px;
    padding: 30px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    .opaBox{
      width: 378px;
      height: 47px;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
      border-radius: 0px 0px 5px 5px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .category-box{
      margin-bottom: 24px;
    }
    .CategoryList{
      overflow-y: auto;
    }
    .categoryItem{
      display: flex;
      cursor: pointer;
      align-items: center;
      margin-bottom: 20px;
      padding: 14px;
      border-left: 4px solid #C9EEF7;
      box-shadow: 0px 4px 10px 0px rgba(93,131,167,0.1);
      position: relative;
      transition: box-shadow .3s;
      .GodetailBtn{
        position: absolute;
        right: 16px;
        bottom: 14px;
        display: flex;
        align-items: center;
        img{
          width: 11px;
          height: 10px;
        }
        p{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #15B7DD;
          line-height: 21px;
          margin-right: 4px;
        }
      }
      .mockExamIcon{
        width: 29px;
        height: 29px;
        margin-right: 11px;
      }
      .content{
        overflow: hidden;
        .name{
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
        .describe{
          margin-top: 12px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 15px;
          letter-spacing: 1px;
        }
      }
    }
    .categoryItem:hover{
      box-shadow: 0px 4px 10px 0px rgba(93, 131, 167, 0.20);
    }
  }
}

.proright {
  position: relative;
  right: 14px;
  width: 20px;
  height: 44px;
}
.proleft {
  position: relative;
  left: 16px;
  width: 20px;
  height: 44px;
}
.change-pic {
  width: 13px;
  height: 12px;
  vertical-align: baseline;
}
// 课程列表样式
.course-list {
  position: relative;
  width: 100%;
  min-height: 146px;
  .course-item {
    display: flex;
    padding-bottom: 25px;
    justify-content: space-between;
    margin-top: 25px;
    border-bottom: 1px solid #f2f2f2;
    &:nth-last-child(2) {
      border: 0;
      padding-bottom: 0;
    }
    .buttxt {
      font-size: 18px;
      font-family: PingFang SC;
      color: #15b7dd;
      text-align: left;
      cursor: pointer;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
      }
    }
    .itemleft {
      flex: 1;
      .lefttop {
        display: flex;
        .side-l {
          width: 283px;
          height: 163px;
          border-radius: 5px;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            object-fit: cover;
          }
        }
        .side-r {
          flex: 1;
          margin-left: 28px;
          .name {
            font-size: 22px;
            font-family: PingFang SC-Medium, PingFang SC;
            cursor: pointer;
            font-weight: 500;
            line-height: 24px;
            color: #333333;
            .tag {
              display: inline-block;
              position: relative;
              top: -4px;
              margin-left: 16px;
              text-align: center;
              line-height: 20px;
              font-style: normal;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              border-radius: 8px 0 8px 0;
              width: 44px;
              height: 20px;
              background: linear-gradient(to right, #3ac5e5, #64e2ff);
            }
            .tag-yellow {
              background: linear-gradient(to right, #ffbd60, #eaa037);
            }
          }
          .lable {
            font-size: 14px;
            display: block;
            font-family: PingFang SC;
            color: #1e97b4;
            margin-top: 18px;
            i {
              margin: 0 5px;
              font-style: normal;
            }
            .progress {
              margin-left: 20px;
              color: #666666;
              img {
                position: relative;
                bottom: 2px;
                width: 16px;
              }
            }
          }

          .time-inner {
            margin-top: 14px;
            // height: 68px;
            .time-item {
              margin-top: 10px;
              img {
                position: relative;
                top: -2px;
                width: 20px;
                margin-right: 6px;
              }
            }
            .timetxt {
              line-height: 21px;
              font-size: 18px;
              font-family: PingFang SC;
              color: #666666;
            }
            .buttxt {
              margin-left: 20px;
            }
          }
        }
      }

      .foot {
        margin-top: 30px;
        display: flex;
        width: 870px;
        justify-content: space-between;
        .speed {
          width: 70%;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          /deep/ .ant-progress-bg {
            height: 16px !important;
          }
          .bar {
            position: relative;
            width: 397px;
            i {
              width: 5px;
              height: 5px;
              background: #1e97b4;
              border-radius: 50%;
              position: absolute;
              right: 10px;
              top: 10px;
            }
            /deep/ .ant-progress-bg {
              border-right: 2px solid #fff;
            }
          }
          .value {
            display: inline-block;
            padding-left: 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
            min-width: 70px;
          }
        }
      }
    }
    .itemright {
      position: relative;
      width: 260px;
      .leftbottom {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

// 学习资料
.electron-list{
  overflow: hidden;
  margin-top: 24px;
  min-height: 200px;
  .electron-item{
    float: left;
    margin-right: 24px;
    margin-bottom: 20px;
    width: calc((100% - 48px) / 3);
    .title{
      background-color: #DCF4FA;
      height: 34px;
      padding-left: 12px;
      span{
        font-weight: 400;
        font-size: 12px;
        color: #15B7DD;
        line-height: 34px;
        img{
          width: 12px;
          margin-bottom: 2px;
          margin-right: 4px;
        }
      }
    }
    .side-info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px;
      min-height: 167px;
      box-shadow: 0 4px 10px 0px rgba(93,131,167,0.1);
      .info{
        display: flex;
        align-items: center;
        margin-right: 10px;
        .cover{
          width: 35px;
          margin-right: 10px;
        }
        >div{
          flex: 1;
          overflow: hidden;
          width: 270px;
          .name{
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 21px;
          }
          .time{
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            margin-top: 5px;
            line-height: 18px;
          }
        }
        
      }
      .info-name{
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 21px;
        margin-top: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn{
        display: flex;
        justify-content: right;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 4px;
        .download{
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 32px;
          padding: 0 22px;
          border-radius: 20px;
          margin-right: 20px;
          border: 1px solid #666666;
          cursor: pointer;
        }
      }
    }
  }
  .electron-item:nth-child(3n){
    margin-right: 0;
  }
}

// 考试报名列表样式
.exam-signup-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  min-height: 200px;
  .exam-signup-item {
    width: calc((100% - 60px) / 3);
    margin-right: 30px;
    margin-bottom: 24px;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.08);
    border-top: 3px solid #cacaca;
    border-radius: 3px 3px 0px 0px;
    padding: 20px;
    position: relative;
    .name {
      font-weight: 500;
      color: #333333;
      line-height: 24px;
      margin-bottom: 12px;
      max-width: 90%;
    }
    .time {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
    .score {
      min-height: 44px;
      span {
        margin-right: 30px;
        i {
          font-style: normal;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .replace {
      display: flex;
      justify-content: space-between;
      min-height: 44px;
    }
    .modify {
      cursor: pointer;
      img {
        width: 14px;
      }
    }
    .btn {
      margin-top: 16px;
      display: flex;
      justify-content: right;
      .all-btn-small {
        display: inline-block;
        margin-left: 10px;
      }
      .all-btn-detail {
        display: inline-block;
        width: 112px;
        height: 34px;
        line-height: 34px;
        border-radius: 40px;
        color: #333333;
        border: 1px solid #666666;
        text-align: center;
        cursor: pointer;
      }
    }
    .left-bottom-icon {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 74px;
    }
    .exam-status-icon {
      position: absolute;
      right: 10px;
      top: 7px;
      width: 40px;
    }
  }
  .exam-signup-item1 {
    border-top: 3px solid #48db46;
  }
  .exam-signup-item2 {
    border-top: 3px solid #fbbf1f;
  }
  .exam-signup-item:nth-child(3n) {
    margin-right: 0;
  }
}
.load-more{
  width: 100%;
  display: block;
  text-align: center;
}
.exam-modal {
  /deep/ .ant-modal-body {
    padding: 24px;
  }
}
.exam-modal-box {
  h4 {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    text-align: center;
  }
  .box {
    margin-top: 24px;
    .title {
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
    .time-inner {
      margin-top: 16px;
      .time {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 18px;
        img {
          width: 14px;
          margin-top: -2px;
          margin-right: 3px;
        }
      }
      .line {
        margin-top: 8px;
      }
    }
    .select-inner {
      padding: 12px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
    .select-active {
      background: @theme;
      border: 1px solid @theme;
      .time {
        color: #ffffff;
      }
    }
  }
  .submit-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel-btn {
      text-align: center;
      border-radius: 23px;
      color: @theme;
      line-height: 34px;
      border: 1px solid @theme;
      width: 114px;
      height: 34px;
      margin-right: 60px;
    }
    span {
      width: 114px;
      font-size: 14px;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
    }
  }
}

.nodata {
  font-size: 20px;
  color: #2d3951;
  a {
    color: #15b7dd;
  }
}
.load {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// 课程列表
.mockList {
  position: relative;
  width: 100%;
  min-height: 340px;
  padding: 0 30px;
  font-family: PingFangMedium;
  // margin-top: 22px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  .listitem {
    width: 41%;
    margin-bottom: 24px;
    .itemdiv {
      width: 433px;
      height: 145px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(93, 131, 167, 0.1);
      border-radius: 16px 16px 16px 16px;
      padding: 12px 16px;
      display: flex;
      .ititmleft {
        width: 120px;
        height: 120px;
        min-width: 120px;
        min-height: 120px;
        box-shadow: 0px 5px 5px 0px rgba(93, 131, 167, 0.1);
        border-radius: 5px 5px 8px 5px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          object-fit: cover;
        }
      }
      .ititmright {
        margin-left: 28px;
        .title {
          min-height: 46px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          display: block;
          line-height: 23px;
        }
        .label {
          font-size: 12px;
          font-weight: 400;
          display: block;
          color: #666666;
          line-height: 21px;
        }
        .all-btn-small {
          font-size: 14px;
          margin-top: 15px;
        }
      }
    }
  }
}
// 证书轮播样式
.slides {
  width: 100%;
  overflow: hidden;
  .imginfo {
    margin: 0 auto;
    width: 359px;
    border-radius: 0px 0px 0px 0px;
    .img1 {
      width: 320px;
      height: 232.2px;
      cursor: pointer;
    }
    .img2 {
      width: 320px;
      height: 452.58px;
      cursor: pointer;
    }
    .txtinfo {
      margin-top: 15px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .txtnum {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      span {
        display: block;
        line-height: 21px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
// 按钮取消
/deep/.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}
/deep/.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}
/*  小号按钮  */
.all-btn-small {
  width: 112px;
  height: 34px;
  line-height: 34px;
  border-radius: 40px;
}
.bottom-content {
  .top {
    padding-left: 46px;
    display: flex;
    align-items: center;
    span {
      font-family: PingFangMedium;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
    }
    .mleft {
      margin-left: 158px;
    }
    .mright {
      margin-left: 423px;
    }
  }
}
.bottoms {
  width: 100%;
  display: flex;
  padding: 0px 30px;
  align-items: center;
  .line {
    width: 1px;
    height: 270px;
    // border: 1px solid #f2f2f2;
    background: #f2f2f2;
    margin: 0 30px;
  }
}
.duration-box {
  position: relative;
  // overflow: hidden;
  width: 305px;
  height: 360px;
  overflow: auto;
  z-index: 9;
  .table {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    // margin-top: 14px;
    display: flex;
    .item_txt {
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px;
      &.pass {
        color: #f79094;
      }
      &.fail {
        color: #8cbd0b;
      }
      &:nth-child(odd) {
        border-right: 0.5px solid #f7f7f9;
        background: #ffffff;
      }
      &:nth-child(even) {
        border-right: 0.5px solid #ffffff;
        background: #f7f7f9;
      }
    }
    // .item_main {
    //    border-top: 2px solid #F79094;
    // }
    .table_left {
      display: inline-block;
      width: 110px;
      // vertical-align: middle;
      vertical-align: text-top;
      .item_main {
        color: #15b7dd;
        border-top: 2px solid #f79094;
      }
    }
    .table_right {
      flex: 1;
      overflow-x: scroll;
      display: inline-block;
      // width: calc(100% - 110px);
      white-space: nowrap;
      // vertical-align: middle;
      vertical-align: text-top;
      .right_item {
        display: inline-block;
        width: 76.5px;
        vertical-align: middle;
        &:last-child {
          .item_txt {
            border-right: 0;
          }
        }
        .item_main {
          color: #666666;
        }
        &:nth-child(1n) {
          .item_main {
            border-top: 2px solid #ffcf11;
          }
        }
        &:nth-child(2n) {
          .item_main {
            border-top: 2px solid #00cfff;
          }
        }
        &:nth-child(3n) {
          .item_main {
            border-top: 2px solid #a0b2f0;
          }
        }
        &:nth-child(4n) {
          .item_main {
            border-top: 2px solid #f79094;
          }
        }
      }
      .right_item1 {
        width: 100%;
      }
      .right_item2 {
        width: 50%;
      }
    }
    /*滚动条样式*/
    .table_right::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }
    .table_right::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    .table_right::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
/*滚动条样式*/
.duration-box::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
.duration-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.duration-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.header-top {
  display: flex;
  align-items: center;
}
#container {
  width: 380px;
  height: 480px;
}
#containers {
  height: 408px;
  width: 480px;
}
.dropdowns {
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(93, 131, 167, 0.1);
  border-radius: 5px 5px 5px 5px;
  padding: 9px 12px;
  display: block;
  align-items: center;
  display: flex;
  margin-left: 44px;
  width: 124px;
  height: 44px;
  .dspan {
    color: #333 !important;
    font-size: 16px !important;
    width: 124px;
    margin-right: 8px;
    display: block;
  }
}
.overflowX {
  overflow-x: scroll;
}

// 通知和信息样式
.Tip {
  height: 48px;
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 20px;
  margin: 0 auto;
  font-family: PingFangMedium;
  display: flex;
  align-items: center;
  padding: 25px 58px;
  .image1 {
    width: 14px;
  }
  .text1 {
    font-size: 16px;
    font-weight: 400;
    color: #15b7dd;
    margin-left: 5px;
    display: block;
  }
  .text2 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    flex: 1;
  }
  .butn {
    font-size: 14px;
    font-weight: 500;
    color: #15b7dd;
    cursor: pointer;
  }
}

@media screen and (max-width: 16000px) {
  .course-list .course-item {
    padding-bottom: 20px;
    margin-top: 20px;
    .itemleft {
      .lefttop {
        .side-l {
          width: 217px;
          height: 125px;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
        }
        .side-r {
          .name {
            font-size: 16px;
          }
          .time-inner {
            .time-item {
              .timetxt {
                font-size: 14px;
              }
              img {
                position: relative;
                top: 1px;
                width: 15px;
              }
              .buttxt {
                font-size: 14px;
              }
            }
          }
        }
      }
      .foot {
        width: 680px;
        .speed .bar {
          width: 349px;
        }
      }
    }
  }
  .slides {
    .imginfo {
      width: 320px;
      img {
        // height: 222px;
      }
      .txtinfo {
        font-size: 18px;
      }
      .txtnum {
        span {
          font-size: 12px;
        }
      }
    }
  }
  .content-box .main-box .main-bot .info-tab .tab-list .tab-title {
    font-size: 18px;
  }
  .main-bot {
    margin-top: 16px;
    .info-title {
      padding: 30px 118px 30px 30px;
    }
  }
  .proright,
  .proleft {
    top: -15px;
  }
  #container {
    width: 357px;
    height: 420px;
  }
  #containers {
    width: 357px;
    height: 420px;
  }
  .header-top {
    span {
      font-size: 20px;
    }
  }
  .bottom-content {
    .top {
      padding-left: 46px;
      display: flex;
      align-items: center;
      span {
        font-family: PingFangMedium;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .mleft {
        margin-left: 118px;
      }
      .mright {
        margin-left: 322px;
      }
    }
  }
}

/deep/.ant-modal-footer {
  border-top: #15b7dd 0px solid;
}
// /deep/.ant-modal-header {
//   display: none;
// }
/deep/.ant-col-4 {
  text-align: left;
  label {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
  }
}
/deep/.ant-modal-body {
  padding: 32px 60px;
}
.bottombtn {
  text-align: center;
  padding-bottom: 30px;
  .ant-btn {
    width: 113px;
    height: 36px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 18px;
  }
}
.centers {
  width: 100%;
  .h4 {
    font-size: 20px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    text-align: center;
  }
  p {
    margin-top: 28px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
/deep/.ant-select-selection {
  border: #fff solid 0px;
}
/deep/.ant-modal-header {
  border: 0 !important;
  padding: 33px 32px 8px;
}
/deep/.ant-modal-title {
  font-size: 20px !important;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #15b7dd !important;
  line-height: 23px !important;
  .icon {
    margin-right: 8px;
  }
}

/deep/.ant-modal-body {
  padding: 24px 65px;
  .title {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
  }
  .imgs {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .imgItem {
      text-align: center;
      img {
        width: 120px;
        height: 120px;
      }
      p {
        margin-top: 8px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #000000;
        line-height: 24px;
      }
    }
  }
}
/deep/.ant-modal-footer {
  border: 0;
  padding-top: 16px;
  padding-bottom: 40px;
  .all-btn-small {
    margin: 0 auto;
  }
}

.teacherName {
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFangMedium;
  color: #666666;
  line-height: 21px;
}
</style>
